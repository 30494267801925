import React, { Fragment, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import Header from "./components/headerFooter/Header";
import Footer from "./components/headerFooter/Footer";
import Userlogin from "./pages/Login";
import Userregister from "./pages/Register";
import Resetpwd from "./pages/Resetpwd";
import Property from "./pages/Property";
import PropertyListing from "./pages/PropertyListing";
import ReferralPage from './pages/Referral';
import VerifyOtp from "./pages/VerifyOtp";
import VerifyOtpPassword from "./pages/VerifyOtpPassword";
import useThemeStore from "./store/themeStore";
import { getTheme, ThemeProps } from "./utils/theme";
import WhiteLabel from "./pages/WhiteLabel";
import Blog from "./pages/Blog";
import ScrollToTop from "./ScrollToTop";
import ServicesPage from "./pages/Services";
import PropertyValuation from "./pages/PropertyValuation";
import useTokenStore from "./store/store";
import "./App.css";

// Message Types
enum MessageType {
  THEME_UPDATE = 'THEME_UPDATE',
  COLOR_UPDATE = 'COLOR_UPDATE',
  THEME_UPDATE_RECEIVED = 'THEME_UPDATE_RECEIVED',
  COLOR_UPDATE_RECEIVED = 'COLOR_UPDATE_RECEIVED',
  COLOR_UPDATE_ERROR = 'COLOR_UPDATE_ERROR'
}

// Constants
const DEBOUNCE_MS = 300;

// Demo site defaults
const DEMO_DEFAULTS = {
  color1: '#1e1b4b',
  color2: '#4f46e5',
  txtColor: '#000000',
  logo: 'https://demo.whitelabelproperty.com/logo.svg',
  favIcon: 'https://demo.whitelabelproperty.com/favicon.ico'
};

// Allowed origins configuration
const ALLOWED_ORIGINS = [
  "http://localhost:3000",
  "http://localhost:3001",
  "https://main.dfcttlwz2z1ar.amplifyapp.com",
  "https://whitelabelproperty.com",
  "https://www.whitelabelproperty.com",
  "https://www.demo.whitelabelproperty.com",
  "https://demo.whitelabelproperty.com"
].map(origin => origin.replace(/\/$/, ''));

// Helper functions
const isEmpty = (value: any): boolean => {
  if (value === null || value === undefined) return true;
  if (typeof value === 'string') return value.trim() === '';
  return false;
};

const getThemeValue = (key: keyof typeof DEMO_DEFAULTS, value: any): any => {
  if (isEmpty(value) && key in DEMO_DEFAULTS) {
    return DEMO_DEFAULTS[key];
  }
  return value;
};

const normalizeOrigin = (origin: string): string => {
  return origin.replace(/\/$/, '').replace(/^(https?:\/\/)www\./, '$1');
};

const isAllowedOrigin = (origin: string): boolean => {
  const normalizedOrigin = normalizeOrigin(origin);
  return ALLOWED_ORIGINS.some(allowed => normalizeOrigin(allowed) === normalizedOrigin);
};

// Components
const ConditionalFooter = () => {
  const location = useLocation();
  const noFooterRoutes = ['/login', '/register', '/resetPassword', '/verifyPasswordotp', '/verifyotp'];
  return noFooterRoutes.includes(location.pathname) ? null : <Footer />;
};

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const location = useLocation();
  const getToken = useTokenStore((state) => state.token);
  
  if (!getToken) {
    const intendedUrl = location.pathname + location.search;
    return (
      <Navigate
        to={`/login?redirectTo=${encodeURIComponent(intendedUrl)}`}
        replace
      />
    );
  }
  return element;
};

function App() {
  const setTheme = useThemeStore((state) => state.setTheme);
  const currentTheme = useThemeStore.getState().theme;
  const [loading, setLoading] = useState(true);

  // Load theme from localStorage
  const loadSavedTheme = useCallback(() => {
    try {
      const savedTheme = localStorage.getItem('whitelabel_theme');
      if (savedTheme) {
        const parsedTheme = JSON.parse(savedTheme);
        const themeWithDefaults = {
          ...parsedTheme,
          color1: getThemeValue('color1', parsedTheme.color1),
          color2: getThemeValue('color2', parsedTheme.color2),
          txtColor: getThemeValue('txtColor', parsedTheme.txtColor),
          logo: getThemeValue('logo', parsedTheme.logo),
          favIcon: getThemeValue('favIcon', parsedTheme.favIcon)
        };
        setTheme(themeWithDefaults);
        setLoading(false);
        console.debug('Loaded saved theme');
        return true;
      }
    } catch (err) {
      console.error('Error loading saved theme:', err);
    }
    return false;
  }, [setTheme]);

  // Fetch theme if not in localStorage
  const fetchTheme = useCallback(async () => {
    if (!loadSavedTheme()) {
      try {
        const response = await getTheme();
        if (response) {
          const themeWithDefaults = {
            ...response,
            color1: getThemeValue('color1', response.color1),
            color2: getThemeValue('color2', response.color2),
            txtColor: getThemeValue('txtColor', response.txtColor),
            logo: getThemeValue('logo', response.logo),
            favIcon: getThemeValue('favIcon', response.favIcon)
          };
          setTheme(themeWithDefaults);
        }
      } catch (err) {
        console.error('Error fetching theme:', err);
      } finally {
        setLoading(false);
      }
    }
  }, [setTheme, loadSavedTheme]);

  // Debounced color update sender
  const debouncedSendColorUpdate = useCallback(
    debounce((colors: any) => {
      try {
        window.parent.postMessage({
          type: MessageType.COLOR_UPDATE,
          colors
        }, ALLOWED_ORIGINS[0]);
        console.debug('Color update sent');
      } catch (err) {
        console.error('Failed to send color update:', err);
      }
    }, DEBOUNCE_MS),
    []
  );

  // Handle incoming theme updates
  const handleMessage = useCallback((event: MessageEvent) => {
    if (!isAllowedOrigin(event.origin)) return;

    const { type, theme } = event.data;
    if (type === MessageType.THEME_UPDATE && theme) {
      try {
        const themeWithDefaults = {
          ...theme,
          color1: getThemeValue('color1', theme.color1),
          color2: getThemeValue('color2', theme.color2),
          txtColor: getThemeValue('txtColor', theme.txtColor),
          logo: getThemeValue('logo', theme.logo),
          favIcon: getThemeValue('favIcon', theme.favIcon)
        };
        
        setTheme(themeWithDefaults);
        localStorage.setItem('whitelabel_theme', JSON.stringify(themeWithDefaults));
        
        // Silent acknowledgment
        window.parent.postMessage({
          type: MessageType.THEME_UPDATE_RECEIVED,
          status: 'success'
        }, event.origin);
      } catch (err) {
        console.error('Error processing theme update:', err);
      }
    }
  }, [setTheme]);

  // Initialize theme and message listener
  useEffect(() => {
    fetchTheme();
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [fetchTheme, handleMessage]);

  // Watch for theme changes and send updates
  useEffect(() => {
    if (currentTheme?.color1 || currentTheme?.color2 || currentTheme?.txtColor) {
      const colors = {
        color1: currentTheme.color1 || DEMO_DEFAULTS.color1,
        color2: currentTheme.color2 || DEMO_DEFAULTS.color2,
        txtColor: currentTheme.txtColor || DEMO_DEFAULTS.txtColor
      };
      
      debouncedSendColorUpdate(colors);
      localStorage.setItem('whitelabel_theme', JSON.stringify({
        ...currentTheme,
        ...colors
      }));
    }
  }, [currentTheme?.color1, currentTheme?.color2, currentTheme?.txtColor, debouncedSendColorUpdate]);

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="App">
      <Router basename={"/"}>
        <ScrollToTop>
          <Fragment>
            <Header />
            <Routes>
              <Route
                path="/"
                element={
                  getThemes && getThemes.hybrid ? (
                    <PropertyListing />
                  ) : (
                    <WhiteLabel />
                  )
                }
              />
              {/* Public Routes */}
              <Route path="/login" element={<Userlogin />} />
              <Route path="/register" element={<Userregister />} />
              <Route path="/resetPassword" element={<Resetpwd />} />
              <Route path="/verifyPasswordOtp" element={<VerifyOtpPassword />} />
              <Route path="/verifyotp" element={<VerifyOtp />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/propertyvaluation" element={<PropertyValuation />} />
              <Route path="/listing" element={<PropertyListing />} />
              <Route path="/Referral" element={<ReferralPage />} />
              {/* Private Routes */}
              <Route path="/listing/:id" element={<PrivateRoute element={<Property />} />} />
              <Route path="/listing/:id/failure" element={<PrivateRoute element={<Property />} />} />
              <Route path="/listing/:id/success" element={<PrivateRoute element={<Property />} />} />
            </Routes>
            <ConditionalFooter />
          </Fragment>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;