import React, { useState, useEffect, useCallback, CSSProperties } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpen,
  faHandshake,
  faChartBar,
  faInfoCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { 
  Building, 
  Bed, 
  BarChart3, 
  BadgePoundSterling, 
  Percent, 
  Home, 
  Waves,
  House,
  LandPlot,
  KeySquare,
  LucideIcon,
} from 'lucide-react';
import { PropertyExtraResult, numberFormat } from '../../utils/properties';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

interface ThemeProps {
  color2: string;
  color1: string;
  txtColor: string;
  name: string;
}

interface FinancialSectionProps {
  property: PropertyExtraResult;
  getThemes: ThemeProps;
}

const formatNumber = (value: number): string => {
  if (value === undefined || isNaN(value)) return "0";
  return Math.ceil(value).toLocaleString();
};

const recalculateFinancials = (
  propData: PropertyExtraResult,
  loan: number,
  interestWhole: number,
  interestDecimal: number
) => {
  const loanPercentage = loan / 100;
  const price = propData.results.price;

  // Combine the whole and decimal parts of the interest
  const combinedInterest = parseFloat(`${interestWhole}.${interestDecimal}`);

  // Calculate deposit
  const deposit = Math.ceil(price * (1 - loanPercentage));

  // Calculate brokage
  const brokage = Math.ceil(price * loanPercentage * 0.01);

  // Calculate stamp duty
  let stampDuty = 0;
  if (price <= 250000) {
    stampDuty = price * 0.05;
  } else if (price <= 925000) {
    stampDuty = 250000 * 0.05 + (price - 250000) * 0.08;
  } else if (price <= 1500000) {
    stampDuty = 250000 * 0.05 + 675000 * 0.08 + (price - 925000) * 0.13;
  } else {
    stampDuty = 250000 * 0.05 + 675000 * 0.08 + 575000 * 0.13 + (price - 1500000) * 0.15;
  }
  stampDuty = Math.ceil(stampDuty);

  // Calculate total investment
  const totalInvestment = Math.ceil(deposit + brokage + stampDuty +
    (propData.results.legal || 0) + (propData.results.setupFeeValuation || 0));

  // Calculate mortgage
  const mortgageAmount = price * loanPercentage;
  const monthlyMortgage = Math.ceil((mortgageAmount * (combinedInterest / 100)) / 12);

  // Calculate net cashflow
  const averageRent = propData.results.averageRent || 0;
  const netCashflow = Math.ceil(averageRent - monthlyMortgage);
  const netAnnualCashflow = Math.ceil(netCashflow * 12);

  // Calculate gross yield
  const annualRent = averageRent * 12;
  const grossYieldValue = ((annualRent / price) * 100).toFixed(1);

  return {
    deposit,
    brokage,
    stampDuty,
    totalInvestment,
    mortgage: monthlyMortgage,
    netCashflow,
    netAnnualCashflow,
    grossYield: grossYieldValue
  };
};

const FinancialSection: React.FC<FinancialSectionProps> = ({ property, getThemes }) => {
  const [interest] = useState<number>(5.00);
  const [interestWhole, setInterestWhole] = useState<number>(Math.floor(interest));
  const [interestDecimal, setInterestDecimal] = useState<number>(parseInt((interest % 1).toFixed(2).split('.')[1]));
  const [loan, setLoan] = useState<number>(75);
  const [formattedPrice, setFormattedPrice] = useState<string>(formatNumber(property.results.price));
  const [updatedProperty, setProperty] = useState<PropertyExtraResult | undefined>(property);
  const [deposite, setDeposite] = useState<number>(0);
  const [brokage, setBrokage] = useState<number>(0);
  const [stampDuty, setStampDuty] = useState<number>(0);
  const [totalInvestment, setTotalInvestment] = useState<number>(0);
  const [mortgage, setMortgage] = useState<number>(0);
  const [netCashflow, setNetCashflow] = useState<number>(0);
  const [netAnnualCashflow, setNetAnnualCashflow] = useState<number>(0);
  const [grossYield, setGrossYield] = useState<string>("");

  const updateFinancials = useCallback(() => {
    if (updatedProperty) {
      const result = recalculateFinancials(updatedProperty, loan, interestWhole, interestDecimal);
      setDeposite(result.deposit);
      setBrokage(result.brokage);
      setStampDuty(result.stampDuty);
      setTotalInvestment(result.totalInvestment);
      setMortgage(result.mortgage);
      setNetCashflow(result.netCashflow);
      setNetAnnualCashflow(result.netAnnualCashflow);
      setGrossYield(result.grossYield);
    }
  }, [updatedProperty, loan, interestWhole, interestDecimal]);

  useEffect(() => {
    updateFinancials();
  }, [updateFinancials]);

  const handlePurchasePriceChange = (formattedValue: string) => {
    const priceValue = parseInt(formattedValue.replace(/,/g, ''), 10);
    if (!isNaN(priceValue) && updatedProperty) {
      setFormattedPrice(formattedValue);
      const updatedProp: PropertyExtraResult = {
        ...updatedProperty,
        results: { ...updatedProperty.results, price: priceValue }
      };
      setProperty(updatedProp);
    }
  };

  const handleInterestWholeChange = (value: string) => {
    const wholeValue = parseInt(value);
    if (!isNaN(wholeValue) && wholeValue >= 0 && wholeValue <= 100) {
      setInterestWhole(wholeValue);
    }
  };
  
  const handleInterestDecimalChange = (value: string) => {
    const decimalValue = parseInt(value);
    if (!isNaN(decimalValue) && decimalValue >= 0 && decimalValue <= 99) {
      setInterestDecimal(decimalValue);
    }
  };
  
  const handleLoan = (value: string) => {
    const loanValue = parseInt(value);
    if (!isNaN(loanValue) && loanValue >= 0 && loanValue <= 100) {
      setLoan(loanValue);
    }
  };

  const handleReset = () => {
    setInterestWhole(5);
    setInterestDecimal(0);
    setLoan(75);
    setFormattedPrice(formatNumber(property.results.price));
    setProperty(property);
  };

  const isWhiteOrShade = (color: string) => {
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      return r >= 240 && g >= 240 && b >= 240;
    }
    return false;
  };

  const sliderStyle = (value: number): CSSProperties => ({
    appearance: 'none',
    width: '100%',
    height: '8px',
    borderRadius: '5px',
    outline: 'none',
    padding: '0',
    margin: '0',
    background: `linear-gradient(to right, ${isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color1} 0%, ${isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color1} ${value}%, #ddd ${value}%, #ddd 100%)`,
  } as CSSProperties);

  const Icon: React.FC<{ icon: IconDefinition | LucideIcon, className?: string, isLucide?: boolean }> = ({ icon, className, isLucide }) => {
    if (isLucide) {
      const LucideIconComponent = icon as LucideIcon;
      return <LucideIconComponent className={className} />;
    }
    return <FontAwesomeIcon icon={icon as IconDefinition} className={className} />;
  };

  const tooltipContent = `Total Investment is calculated by adding:<br />
  - Deposit<br />
  - Brokerage<br />
  - Stamp Duty<br />
  - Legal Fees<br />
  - Setup Fees amounts
  `;

  return (
    <div className="container mx-auto p-4">
      <div className="grid lg:grid-cols-3 gap-4">
        {/* Property Info Card - Visible only on mobile */}
        <div className="lg:hidden bg-white border-gray-300 border rounded-lg shadow-lg p-4 hover:shadow-xl transition duration-150 ease-in-out">
          <PropertyInfoCard property={property} grossYield={grossYield} themeName={getThemes.name} />
        </div>

        {/* Left side: Financial Information and Calculate Your Returns (2/3 width on large screens) */}
        <div className="lg:col-span-2 border-gray-300 border bg-white rounded-lg shadow-lg p-4 hover:shadow-xl transition duration-150 ease-in-out" style={{backgroundColor: "white" }}>
          <div className="grid md:grid-cols-2 gap-4 relative">
            {/* Vertical/Horizontal divider line */}
            <div className="absolute top-0 bottom-0 left-1/2 w-px bg-gray-300 md:block hidden"></div>
            <div className="absolute left-0 right-0 top-1/2 h-px bg-gray-300 md:hidden block"></div>

            {/* Financial Information */}
            <div className="flex flex-col items-center">
              <h3 className="text-xl font-semibold tracking-normal text-primarycolor text-center pt-10 mb-4">
                Financial Information
              </h3>
              <div className="text-sm grid grid-cols-1 pt-4 gap-2 w-full max-w-xs">
                {[
                  { icon: BadgePoundSterling, label: "Purchase Price", value: updatedProperty?.results.price, isLucide: true },
                  { icon: faEnvelopeOpen, label: "Deposit", value: deposite },
                  { icon: faHandshake, label: "Brokerage (1% of borrowing)", value: brokage },
                  { icon: BadgePoundSterling, label: "Stamp Duty", value: stampDuty, isLucide: true },
                  { icon: faChartBar, label: "Total investment", value: totalInvestment },
                ].map((item, index) => (
                  <div key={index} className="text-left text-primarycolor flex justify-between items-center">
                    <h4 className="flex items-center gap-x-1">
                      <Icon icon={item.icon} className="w-4 h-4" isLucide={item.isLucide} />
                      <span className="text-s font-bold">{item.label}:  </span>
                      {item.label === "Total investment" && (
                        <>
                          <FontAwesomeIcon 
                            icon={faInfoCircle} 
                            className="ml-1 text-gray-400 cursor-pointer" 
                            data-tooltip-id="totalInvestmentTooltip"
                            data-tooltip-html={tooltipContent}
                            style={{ color: getThemes.color2 }}
                          />
                          <ReactTooltip 
                            id="totalInvestmentTooltip" 
                            place="top"
                            className="custom-tooltip"
                            style={{
                              backgroundColor: "#333",
                              color: "#fff",
                              borderRadius: "4px",
                              padding: "10px",
                              fontSize: "12px",
                              maxWidth: "200px",
                              lineHeight: "1.4",
                              whiteSpace: "pre-wrap"
                            }}
                          />
                        </>
                      )}
                    </h4>
                    <span className="text-xs">£{formatNumber(Number(item.value))}</span>
                  </div>
                ))}
                {/* Grey line with padding */}
                <div className="flex justify-center my-2">
                  <div className="w-full" style={{ borderTop: "1px solid #ccc", padding: "2px 0" }}></div>
                </div>
                {[
                  { icon: House, label: "Market Rent", value: property?.results.averageRent, isLucide: true },
                  { icon: BadgePoundSterling, label: `Mortgage ${property?.results.mortgageLabel}`, value: mortgage, isLucide: true },
                  { icon: BadgePoundSterling, label: "Net cashflow", value: netCashflow, isLucide: true },
                  { icon: BadgePoundSterling, label: "Net annual cashflow", value: netAnnualCashflow, isLucide: true },
                ].map((item, index) => (
                  <div key={index} className="text-left text-primarycolor flex justify-between items-center">
                    <h4 className="flex items-center gap-x-1">
                      <Icon icon={item.icon} className="w-4 h-4" isLucide={item.isLucide} />
                      <span className="text-s font-bold">{item.label}:  </span>
                    </h4>
                    <span className="text-xs">£{formatNumber(Number(item.value))}</span>
                  </div>
                ))}
              </div>
            </div>
      

          {/* Calculate Your Returns */}
            <div className="flex flex-col items-center md:pt-10 pt-20">
              <h3 className="text-xl font-semibold tracking-normal text-center mb-2">
                Calculate Your Returns
              </h3>
              <p className="text-sm mb-4 pt-4 text-center">
                Move the sliders to understand the financials based on your circumstances.
              </p>

              {/* Sliders */}
              <div className="w-full max-w-xs">
                {[
                  { label: "Purchase Price", type: "text", value: formattedPrice, onChange: handlePurchasePriceChange, unit: "£" },
                  {
                    label: "Interest Rate",
                    customInput: (
                      <div className="w-full mb-4">
                        <div className="flex flex-col items-center">
                          <div className="flex items-center justify-center space-x-1">
                            <input
                              type="number"
                              value={interestWhole}
                              onChange={(e) => handleInterestWholeChange(e.target.value)}
                              className="w-16 p-1 text-sm border rounded text-center"
                              min={0}
                              max={100}
                            />
                            <span className="px-2">.</span>
                            <input
                              type="number"
                              value={interestDecimal}
                              onChange={(e) => handleInterestDecimalChange(e.target.value)}
                              className="w-16 p-1 text-sm border rounded text-center"
                              min={0}
                              max={99}
                            />
                            <span className="ml-2">%</span>
                          </div>
                        </div>
                      </div>
                    )
                  },
                  { label: "Loan To Value", type: "range", value: loan.toString(), onChange: handleLoan, min: 0, max: 100 },
                ].map((slider, index) => (
                  <div key={index} className="mb-4">
                    <h4 className="text-sm font-medium mb-1 text-center">{slider.label}</h4>
                    <div className="flex items-center">
                      {slider.type === "range" && <span className="text-xs mr-2">1</span>}
                      {slider.customInput ? (
                        slider.customInput
                      ) : (
                        <input
                          type={slider.type}
                          value={slider.value}
                          onChange={(e) => slider.onChange(e.target.value)}
                          className={slider.type === "text" ? "w-full p-1 text-sm border rounded text-center" : ""}
                          min={slider.min}
                          max={slider.max}
                          style={slider.type === "range" ? sliderStyle(Number(slider.value)) : undefined}
                        />
                      )}
                      {/* Display the unit (%, £) if available */}
                      {slider.unit && <span className="ml-2">{slider.unit}</span>}
                      {/* Display the range max label (e.g. "100") for range inputs */}
                      {slider.type === "range" && <span className="text-xs ml-2">100</span>}
                    </div>
                    {/* Show the current value of the range slider below it */}
                    {slider.type === "range" && <span className="text-xs text-center block">{slider.value}</span>}
                  </div>
                ))}

              {/* Style for range input */}
              <style>{`
                input[type="range"] {
                  -webkit-appearance: none;
                  appearance: none;
                }
                input[type="range"]::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background-color: ${isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color1};
                  cursor: pointer;
                  transition: background-color .15s ease-in-out;
                }
                input[type="range"]::-moz-range-thumb {
                  width: 20px;
                  height: 20px;
                  border: 0;
                  border-radius: 50%;
                  background-color: ${isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color2};
                  cursor: pointer;
                  transition: background-color .15s ease-in-out;
                }
                input[type="range"]::-ms-thumb {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background-color: ${isWhiteOrShade(getThemes?.color2) ? "black" : getThemes.color1};
                  cursor: pointer;
                  transition: background-color .15s ease-in-out;
                }
                input[type="range"]::-webkit-slider-thumb:hover,
                input[type="range"]::-moz-range-thumb:hover,
                input[type="range"]::-ms-thumb:hover {
                  background-color: ${isWhiteOrShade(getThemes?.color2) ? "black" : getThemes.color1};
                }
              `}</style>
            </div>

              {/* Reset Values Button */}
              <div className="text-center mt-4">
                <button
                  onClick={handleReset}
                  style={{
                    background: isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color1,
                    color: isWhiteOrShade(getThemes.color1) ? "white" : getThemes.txtColor, // Text color logic
                    padding: "10px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    transition: "background 0.3s ease",
                    boxSizing: "border-box"
                  } as CSSProperties}
                  onMouseOver={(e) => {
                    e.currentTarget.style.background = isWhiteOrShade(getThemes?.color2) ? "black" : getThemes.color2; // Use black if color2 is white or near-white
                    e.currentTarget.style.color = isWhiteOrShade(getThemes?.color1) ? "white" : getThemes.txtColor; // Adjust text color on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.background = isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color1;
                    e.currentTarget.style.color = isWhiteOrShade(getThemes.color1) ? "white" : getThemes.txtColor; // Adjust text color on mouse out
                  }}
                >
                  Reset Values
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Property Info Card - Visible only on larger screens */}
        <div className="hidden lg:block bg-white border-gray-300 border rounded-lg shadow-lg p-4 hover:shadow-xl transition duration-150 ease-in-out" style={{backgroundColor: 'white'}}>
          <PropertyInfoCard property={property} grossYield={grossYield} themeName={getThemes.name} />
        </div>
      </div>
    </div>
  );
};

// PropertyInfoCard component
const PropertyInfoCard: React.FC<{ property: PropertyExtraResult; grossYield: string; themeName: string }> = ({ property, grossYield, themeName }) => {
  const InfoItem = ({ icon: Icon, label, value }: { icon: React.ElementType, label: string, value: string | number | React.ReactNode }) => (
    <div className="flex items-center justify-between py-1">
      <div className="flex items-center space-x-1 flex-shrink-0">
        <Icon className="w-4 h-4" />
        <span className="text-xs font-bold whitespace-nowrap">{label}:  </span>
      </div>
      <div className="text-xs text-right ml-2">{value}</div>
    </div>
  );

  const excludedThemes = ['properties.mypropertylisting.co.uk', 'Test Site 1', 'Test Site 2'];

  const infoItems = [
    { icon: Building, label: "Type", value: property?.results.houseSubtype || 'N/A' },
    { icon: Bed, label: "Bedrooms", value: property?.results.bedrooms ?? 'N/A' },
    { icon: KeySquare, label: "Tenure", value: property?.results.tenureType || 'N/A' },
    { icon: BarChart3, label: "Strategy", value: property?.results.strategy || 'N/A' },
    { icon: BadgePoundSterling, label: "Price", value: property?.results.price ? `£${numberFormat(property?.results.price as number)}` : 'N/A' },
    ...(!excludedThemes.includes(themeName) ? [
      { icon: Home, label: "Zoopla Valuation", value: property?.results.estimatedValue ? `£${numberFormat(property?.results.estimatedValue as unknown as number)}` : 'N/A' },
    ] : []),
    { icon: Home, label: "Occupancy", value: property?.results.occupancy || 'N/A' },
    { icon: Percent, label: "Gross Yield", value: grossYield ? `${grossYield}%` : 'N/A' },
    { icon: BadgePoundSterling, label: "Current Rent", value: property?.results.currentRent ? `£${numberFormat(property?.results.currentRent as number)}` : 'N/A' },
    { icon: BadgePoundSterling, label: "Market Rent", value: property?.results.averageRent ? `£${numberFormat(property?.results.averageRent as unknown as number)}` : 'N/A' },
    { icon: LandPlot, label: "Size", value: property?.results.floorsqft ? `${property?.results.floorsqft} sq ft` : 'N/A' },
    { icon: BarChart3, label: "EPC", value: property?.results.epc || 'N/A' },
    { icon: Waves, label: "Flood Risk", value: property?.results.floodRisk || 'N/A' }
  ];

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="border-b border-gray-300 py-2 px-3">
        <h3 className="text-xl pt-4 pb-4 font-semibold">Property Information</h3>
      </div>
      <div className="px-3 py-2">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-3">
          {infoItems.map((item, index) => (
            <React.Fragment key={index}>
              <InfoItem icon={item.icon} label={item.label} value={item.value} />
              {(index % 2 === 1 || index === infoItems.length - 1) && item.label !== "Flood Risk" && (
                <div className="col-span-full">
                  <hr className="border-t border-gray-200 my-1" />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinancialSection;
