import React from 'react';
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCity } from '@fortawesome/free-solid-svg-icons';
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";

interface NavItemProps {
  icon: IconDefinition;
  text: string;
  isActive: boolean;
  linkTo: string;
}

interface PropertyNavigationProps {
  currentPage: string;
}

interface RewardItem {
  service: string;
  reward: string;
}

const NavItem: React.FC<NavItemProps> = ({ icon, text, isActive, linkTo }) => (
  <NavLink 
    to={linkTo} 
    className={`flex flex-col items-center text-xs sm:text-sm md:text-base lg:text-sm xl:text-xs px-4 py-2 rounded-lg transition-colors duration-200 ease-in-out ${
      isActive ? 'text-white bg-blue-600' : 'text-gray-700 hover:bg-gray-200'
    }`}
  >
    <FontAwesomeIcon 
      icon={icon} 
      className="text-lg sm:text-xl md:text-2xl lg:text-xl xl:text-lg mb-1" 
    />
    <span>{text}</span>
  </NavLink>
);

const PropertyNavigation: React.FC<PropertyNavigationProps> = ({ currentPage }) => (
  <div className="max-w-7xl mx-auto my-8 px-4">
    <nav className="flex justify-between sm:justify-start items-center gap-2 sm:gap-4 flex-wrap">
      <NavItem 
        icon={faMagnifyingGlass} 
        text="Find Property" 
        isActive={currentPage === 'find'}
        linkTo="/listing"
      />
      <NavItem 
        icon={faCity} 
        text="Explore Services" 
        isActive={currentPage === 'services'}
        linkTo="/Services"
      />
    </nav>
  </div>
);

const ReferralPage: React.FC = () => {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const rewardItems: RewardItem[] = [
    { service: 'Listings', reward: '20%–50% of the listing fee' },
    { service: 'Sales', reward: '20%–50% of the sourcing fee' },
    { service: 'Brokerage', reward: '20% of the total fee' },
    { service: 'Legals', reward: '£100–£200 per referral' },
    { service: 'Lettings', reward: '2 months management fee' },
    { service: 'Refurbishments', reward: '2.5% of the total job value' }
  ];

  return (
    <div className="ReferralPage bg-primarycolor pt-28" style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Refer & Earn</title>
        <meta name="description" content="Refer clients and earn rewards with My Property Group" />
      </Helmet>

      <PropertyNavigation currentPage="referral" />

      <div className="relative w-full" >
        <div className="max-w-7xl mx-auto px-4 p-4 sm:px-6 lg:px-8 mb-12">
          <div className="text-center border rounded-xl shadow-md p-8 bg-white" style={{ backgroundColor: 'white' }}>
            <h1 className="text-4xl font-bold text-black sm:text-5xl md:text-6xl relative inline-block mb-6">
              Refer Your Clients to us and Earn
            </h1>
            <p className="mt-3 max-w-3xl mx-auto text-base text-black sm:text-lg md:mt-5 md:text-xl">
              At <span className="font-bold">My Property Group</span>, we believe in building strong, long-lasting partnerships. If you know someone who could benefit from our services, we'd love to hear from you.
            </p>
          </div>
        </div>

        <div className="relative w-full" style={{ 
        backgroundImage: `url(${backgroundPattern})`, 
        backgroundRepeat: 'repeat', 
        backgroundSize: '100px 100px',
        padding: '4rem 0'
      }}>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white rounded-xl shadow-md p-8" style={{ backgroundColor: 'white' }}>
            <div className="prose max-w-none">
              <p className="text-lg mb-6">
                Whether your referral needs help with <span className="font-bold">Brokerage</span>, <span className="font-bold">Legals</span>, <span className="font-bold">Refurbishments</span>, <span className="font-bold">Lettings</span>, or would like to become a White Label Partner, we're here to provide tailored solutions to meet their unique needs.
              </p>
              
              <p className="text-lg mb-6">
                As a thank-you for your referrals, we offer attractive earning opportunities tailored to each service:
              </p>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                {rewardItems.map((item, index) => (
                  <div key={index} className="p-4 border rounded-lg bg-gray-50">
                    <h3 className="font-bold text-lg mb-2">{item.service}</h3>
                    <p>{item.reward}</p>
                  </div>
                ))}
              </div>

              <p className="text-lg mb-6">
                Start earning today while helping others discover tailored property solutions. Your recommendations mean the world to us!
              </p>

              <p className="text-xl font-bold">
                Fill in the details below to share their information with us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ReferralPage;