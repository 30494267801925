import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ThemeProps } from "../utils/theme";

interface ThemeState {
  theme: ThemeProps | undefined;
  setTheme: (theme: ThemeProps) => void;
  removeToken: () => void;
}

const themeStore = (set: any): ThemeState => ({
  theme: undefined,
  setTheme: (theme: ThemeProps) => {
    // Handle logo if it exists in theme
    const processedTheme = { ...theme };
    
    set((state: ThemeState) => ({
      theme: processedTheme,
    }));
  },
  removeToken: () => {
    set(() => ({
      theme: undefined,
    }));
  },
});

const useThemeStore = create<ThemeState>()(
  devtools(
    persist(themeStore, {
      name: "theme",
      partialize: (state) => ({
        theme: state.theme,
      }),
    })
  )
);

export default useThemeStore;